<template>
  <div
    ref="dropdownItem"
    class="dropdown status-selector select-dropdown"
    :class="{open: opened}"
  >
    <button
      class="btn dropdown-toggle"
      type="button"
      :disabled="statusDisabled"
      @click="opened = !opened"
    >
      <i
        class="fas fa-circle"
        :class="optionSelected.icon"
      />
      {{ optionSelected.text }}
    </button>
    <div
      class="dropdown-menu"
      :class="{show: opened}"
    >
      <a
        v-for="option in filteredOptions"
        :key="option.value"
        class="dropdown-item"
        href="javascript:void(0)"
        @click="updateOption(option)"
      >{{ option.text }}</a>
    </div>
  </div>
</template>

<script>

import moment from 'moment'
import {
  STATUS_INACTIVE,
  STATUS_ACTIVE,
  STATUS_STRINGS,
  STATUS_ICONS,
  STATUS_INVITATION_SENT,
  STATUS_NOT_INVITED,
  STATUS_PASSWORD_RESET
} from '@/data/userStatusValues'

export default {
  name: 'StatusSelector',
  props: ['status', 'statusChangedAt', 'userData'], // eslint-disable-line vue/require-prop-types
  data () {
    const options = this.getAvailableOptions()
    return {
      availableOptions: [],
      optionSelected: null,
      opened: false,
      options: options,
      initialStatus: null
    }
  },
  computed: {
    filteredOptions () {
      const availableOptions = this.getAvailableOptions()
      return availableOptions.filter(o => this.optionSelected.value !== o.value)
    },
    statusDisabled () {
      return false
    }
  },
  created () {
    this.initialStatus = this.status
    this.availableOptions = this.getAvailableOptions()
    this.optionSelected = this.getSelectedOption(this.availableOptions)
    document.addEventListener('click', this.handleClickOutside)
  },
  methods: {
    getAvailableOptions () {
      let availableOptions = null
      switch (this.initialStatus) {
        case STATUS_NOT_INVITED:
        case STATUS_INVITATION_SENT:
        case STATUS_PASSWORD_RESET:
          availableOptions = this.getActualAndInactivateOption()
          break
        case STATUS_ACTIVE:
        case STATUS_INACTIVE:
          availableOptions = this.getActiveAndInactiveOptions()
          break
      }
      return availableOptions
    },
    getActualAndInactivateOption () {
      return [
        this.getActualStatusAsOption(),
        this.getInactiveOption()
      ]
    },
    getActiveAndInactiveOptions () {
      return [
        this.getActiveOption(),
        this.getInactiveOption()
      ]
    },
    getActualStatusAsOption () {
      return {
        text: STATUS_STRINGS[this.status],
        value: this.status,
        icon: STATUS_ICONS[this.status]
      }
    },
    getActiveOption () {
      return {
        text: STATUS_STRINGS[STATUS_ACTIVE],
        value: STATUS_ACTIVE,
        icon: STATUS_ICONS[STATUS_ACTIVE]
      }
    },
    getInactiveOption () {
      return {
        text: STATUS_STRINGS[STATUS_INACTIVE],
        value: STATUS_INACTIVE,
        icon: STATUS_ICONS[STATUS_INACTIVE]
      }
    },
    getSelectedOption (options) {
      const userInvitationActivity = this.userData.account_information ? this.userData.account_information.last_invitation_activity : null
      const lastInvitationDate = userInvitationActivity ? userInvitationActivity.datetime : null

      for (let i = 0, len = options.length; i < len; i++) {
        if (parseInt(this.status) === parseInt(options[i].value)) {
          if (this.status === STATUS_INVITATION_SENT && lastInvitationDate) {
            const now = moment().utc()
            const invitedSince = moment.utc(lastInvitationDate)
            const daysAgo = now.diff(invitedSince, 'days')
            if (daysAgo > 0) {
              options[i].text += ` ${daysAgo}d ago`
            }
          }
          return options[i]
        }
      }
    },
    updateOption (option) {
      if (option.value !== this.optionSelected.value) {
        this.optionSelected = option
        this.$emit('statusSelected', option.value)
      }
      this.opened = false
    },
    handleClickOutside (event) {
      const dropdownItem = this.$refs.dropdownItem
      const target = event.target
      if (dropdownItem && dropdownItem !== target && !dropdownItem.contains(target)) {
        this.opened = false
      }
    }
  }
}
</script>
