<template>
  <div class="location-selector">
    <div class="header">
      <label>{{ $t('location_s') }} *</label>
      <span>
        <a
          href="javascript:void(0)"
          @click="selectAll"
        >{{ $t('all') }}</a> <span>|</span> <a
          href="javascript:void(0)"
          @click="selectNone"
        >{{ $t('none') }}</a>
      </span>
    </div>
    <div class="locations-checkboxes">
      <div
        v-for="location in locations"
        :key="location.id"
      >
        <div class="checkbox-container">
          <input
            :id="location.id"
            v-model="selectedLocations"
            type="checkbox"
            :name="location.id"
            :value="location.id"
            style="display:none"
            @input="emitChange"
          >
          <i
            :class="{'fal fa-check-square': selectedLocations.includes(location.id), 'fal fa-square': !selectedLocations.includes(location.id)}"
            @click="selectionChange(location.id)"
          />
          <label :for="location.id">{{ location.name }}</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { SettingsCrud } from '@/mixins/SettingsCrud'
import { GET_LOCATIONS } from '@/store/modules/locations/constants'

export default {
  name: 'LocationSelector',
  mixins: [SettingsCrud],
  props: {
    values: {
      default: null,
      type: Array
    }
  },
  data () {
    return {
      selectedLocations: []
    }
  },
  computed: {
    ...mapGetters({
      locations: 'getLocations'
    })
  },
  created () {
    if (!this.locations.length) {
      this.getData(GET_LOCATIONS).then(() => {
        this.initValues()
      })
    } else {
      this.initValues()
    }
  },
  methods: {
    initValues () {
      this.selectedLocations = this.values ? this.locations.filter(l => this.values.includes(l.id)).map(l => l.id) : []
      if (this.selectedLocations.length === 0 && this.locations.length === 1) {
        this.selectionChange(this.locations[0].id)
      }
      if (this.values) {
        this.emitChange()
      }
    },
    selectAll () {
      this.selectedLocations = this.locations.map(l => l.id)
      this.emitChange()
    },
    selectNone () {
      this.selectedLocations = []
      this.emitChange()
    },
    selectionChange (value) {
      const index = this.selectedLocations.findIndex(v => v === value)
      if (index === -1) {
        this.selectedLocations.push(value)
      } else {
        this.selectedLocations.splice(index, 1)
      }
      this.emitChange()
    },
    emitChange () {
      this.$nextTick().then(() => {
        this.$emit('selectedLocationsChanged', this.selectedLocations)
      })
    }
  }

}
</script>

<style lang="scss">
.location-selector {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #B3C1D1;
    margin-bottom: 15px;
    > span {
      margin-right: 10px;
      color: #0a3560;
      margin-top: -7px;
      display: flex;
      a {
        font-size: 12px;
        font-family: MuseoSans_300;
        font-weight: 300;
        color: #485d72;
        text-transform: capitalize;
        &:hover {
          color: #2068ac;
          text-decoration: none;
        }
      }
      span {
        color: #8097ad;
        font-size: 14px;
        margin: -1px 2px 0 2px;
      }
    }
  }
  .checkbox-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-left: 15px;
    label {
      margin-bottom: 0;
      margin-left: 5px !important;
    }
  }
}
</style>
