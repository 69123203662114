<template>
  <div
    class="clinician-layout"
    @click="SET_SIDEBAR_ADD_STATUS"
  >
    <MyAccountClinician />
    <ModalWrapper />
    <ClinicianNavbar :user="user" />
    <ClinicianSidebar />
    <div
      class="settings-layout"
      :class="{'opened': settingsOpen}"
    >
      <div class="nav flex-column nav-pills">
        <div class="settings-title layout-title">
          <h3>{{ $t('settings') }}</h3>
          <i
            class="fal"
            :class="{'fa-angle-left': settingsOpen, 'fa-angle-right': !settingsOpen}"
            @click="toggleSettingsOpen"
          />
        </div>
        <router-link
          v-for="(item, index) in items"
          :key="index"
          :to="{name: item.link}"
          class="nav-link"
          active-class="active"
        >
          {{ item.title }}
        </router-link>
      </div>
      <div class="tab-content">
        <SettingsLayoutTopBar
          :item="currentItem"
          @openCrudModal="showModal = true"
          @addPatient="addPatient"
          @openAddInOverlay="openAddInOverlay"
        >
          <component
            :is="currentItem.topBarExtraActions"
            v-if="currentItem && currentItem.topBarExtraActions"
            slot="extraActions"
            @filtersChanged="filtersChanged"
          />
        </SettingsLayoutTopBar>
        <router-view ref="view" />
      </div>
    </div>
    <CrudModal
      v-if="showModal || settingEditingInForm"
      :class="`${currentItem ? currentItem.buttonText : ''}-form-modal`"
      :title="currentItem.modalTitle"
      :is-update="!!settingEditingInForm"
      :prompt-message="currentItem.prompt"
      :save-text="customSaveText || $t('saveAndClose')"
      :custom-error="customError"
      @close="closeModal"
      @save="triggerSave"
    >
      <component
        :is="currentItem.formComponent"
        slot="body"
        ref="formComponent"
        @close="closeModal"
        @updateClinicalUserSaveText="updateClinicalUserSaveText"
        @footerError="e => customError = e"
      />
      <component
        :is="currentItem.extraBottomPanelLeftComponent"
        slot="bottomPanelLeft"
        @deleteItem="deleteItem()"
      />
      <component
        :is="currentItem.extraHeaderComponent"
        slot="extraHeaderInfo"
      />
      <component
        :is="currentItem.extraActionButtons"
        v-if="isClinicalUsersForm && settingEditingInForm"
        slot="extraActionButtons"
        @close="closeModal"
      />
      <StatusSelector
        v-if="isClinicalUsersForm"
        slot="extraTitleContent"
        :user-data="settingEditingInForm"
        :status="parseInt(settingEditingInForm.account_information.status)"
        :status-changed-at="settingEditingInForm.account_information.status_changed_at"
        @statusSelected="statusSelected"
      />
    </CrudModal>
    <overlay
      v-if="addingPatient"
      @close="closeOverlay"
    >
      <template slot="content">
        <ClinicianPatientFormWrapper
          :stay-after-finish="true"
          @closeOverlay="closeOverlay"
        />
      </template>
    </overlay>
  </div>
</template>

<script>
import ClinicianNavbar from '@/components/layout/ClinicianNavbar'
import ClinicianSidebar from '@/components/layout/ClinicianSidebar'
import MyAccountClinician from '@/components/clinician/MyAccountClinician'
import SettingsLayoutTopBar from '@/components/admin/settings/SettingsLayoutTopBar'
import SettingsPatientUsersForm from '@/components/admin/settings/forms/SettingsPatientUsersForm'
import SettingsClinicalUsersForm from '@/components/admin/settings/forms/SettingsClinicalUsersForm'
import SettingsClinicalUsersBottomPanelLeft from '@/components/admin/settings/extra/clinicalUsers/SettingsClinicalUsersBottomPanelLeft'
import SettingsUsersExtraHeader from '@/components/admin/settings/extra/users/SettingsUsersExtraHeader'
import SettingsPatientUsersBottomPanelLeft from '@/components/admin/settings/extra/patientUsers/SettingsPatientUsersBottomPanelLeft'
import SettingsClinicalUsersExtraActionButtons from '@/components/admin/settings/extra/clinicalUsers/SettingsClinicalUsersExtraActionButtons'
import SettingsLocationBottomPanelLeft from '@/components/admin/settings/extra/location/SettingsLocationBottomPanelLeft'
import SettingsClinicalTeamForm from '@/components/admin/settings/forms/SettingsClinicalTeamForm'
import AccountStatusFilter from '@/components/admin/settings/extra/patientUsers/AccountStatusFilter'
import SettingsClinicalUsersFilters from '@/components/admin/settings/extra/clinicalUsers/SettingsClinicalUsersFilters'
import SettingsProgramsForm from '@/components/admin/settings/forms/SettingsProgramsForm'
import SettingsLocationsForm from '@/components/admin/settings/forms/SettingsLocationsForm'
import StatusSelector from '@/components/common/StatusSelector'
import ModalWrapper from '@/components/modal/modalWrapper'
import CrudModal from '@/components/common/CrudModal'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { EDITING_SETTING } from '@/store/modules/locations/constants'
import UserManagementComponentMixin from '@/mixins/UserManagement/UserManagementComponentMixin'
import ClinicianPatientFormWrapper from '@/components/clinician/patients/ClinicianPatientFormWrapper'
import Overlay from '@/components/clinician/Overlay'
import { componentRefreshSingleton } from '@/mixins/ComponentRefresh/ComponentRefreshSingleton'

export default {
  name: 'SettingsLayout',
  components: {
    ClinicianNavbar,
    SettingsLayoutTopBar,
    ClinicianSidebar,
    MyAccountClinician,
    CrudModal,
    StatusSelector,
    ModalWrapper,
    Overlay,
    ClinicianPatientFormWrapper,
    SettingsPatientUsersForm,
    SettingsClinicalUsersForm,
    SettingsClinicalUsersBottomPanelLeft,
    SettingsUsersExtraHeader,
    SettingsPatientUsersBottomPanelLeft,
    SettingsClinicalUsersExtraActionButtons,
    SettingsClinicalUsersFilters,
    SettingsLocationBottomPanelLeft,
    SettingsClinicalTeamForm,
    AccountStatusFilter,
    SettingsProgramsForm,
    SettingsLocationsForm
  },
  // Todo - https://owloutcomes.atlassian.net/browse/OWL-8407
  // beforeDestroy () {
  //   document.body.classList.remove('body-settings-layout')
  // },
  mixins: [UserManagementComponentMixin],
  data () {
    return {
      customError: '',
      searchQuery: '',
      customSaveText: '',
      addingPatient: false,
      showModal: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'loggedInUser',
      settingEditingInForm: 'getSettingEditingInForm',
      settingsOpen: 'getSettingsOpen'
    }),
    currentItem () {
      // If match the route on any of its children
      return this.items.find(i => this.$route.matched.some(r => r.name === i.link))
    },
    isLocationsForm () {
      return this.settingEditingInForm && this.currentItem.formComponent === 'SettingsLocationsForm'
    },
    isClinicalUsersForm () {
      return this.settingEditingInForm && this.currentItem.formComponent === 'SettingsClinicalUsersForm'
    },
    items () {
      const items = []
      if (this.userHasAccessToSettingsPatientUsers()) {
        items.push({
          title: this.$t('patientUsers'),
          buttonText: this.$t('patient'),
          addCustomAction: 'addPatient',
          prompt: this.$t('patient'),
          modalTitle: this.settingEditingInForm && this.settingEditingInForm.person_information ? `Edit ${this.settingEditingInForm.person_information.full_name}` : `Add ${this.$tc('patient', 1)}`,
          formComponent: 'SettingsPatientUsersForm',
          extraHeaderComponent: 'SettingsUsersExtraHeader',
          extraBottomPanelLeftComponent: 'SettingsPatientUsersBottomPanelLeft',
          topBarExtraActions: 'AccountStatusFilter',
          link: 'SettingsPatientUsers',
          placeholder: ''
        })
      }
      if (this.userHasAccessToSettingsClinicalUsers()) {
        items.push({
          title: this.$t('clinicalUsers'),
          buttonText: this.$t('user'),
          prompt: this.$t('user'),
          modalTitle: this.settingEditingInForm ? `Edit ${this.settingEditingInForm.first_name} ${this.settingEditingInForm.last_name}` : `Add ${this.$t('user')}`,
          formComponent: 'SettingsClinicalUsersForm',
          extraBottomPanelLeftComponent: 'SettingsClinicalUsersBottomPanelLeft',
          extraHeaderComponent: 'SettingsUsersExtraHeader',
          extraActionButtons: 'SettingsClinicalUsersExtraActionButtons',
          saveText: this.settingEditingInForm ? this.$t('saveAndClose') : this.$t('saveAndInvite'),
          link: 'SettingsClinicalUsers',
          topBarExtraActions: 'SettingsClinicalUsersFilters',
          placeholder: this.$t('allUsers')
        })
      }
      if (this.userHasAccessToAddProgram()) {
        items.push({
          title: this.$tc('programs', 2),
          buttonText: this.$tc('programs', 1),
          addCustomAction: 'openAddInOverlay',
          prompt: this.$tc('programs', 1),
          modalTitle: this.settingEditingInForm ? 'Edit ' : `Add ${this.$tc('programs', 1)}`,
          formComponent: 'SettingsProgramsForm',
          link: 'SettingsPrograms',
          placeholder: this.$tc('programs', 2)
        })
      }
      if (this.userHasAccessToAddClinicalTeam()) {
        items.push({
          title: this.$t('clinicalTeams'),
          buttonText: this.$tc('teams', 1),
          addCustomAction: 'openAddInOverlay',
          prompt: this.$tc('teams', 1),
          modalTitle: this.settingEditingInForm ? 'Edit ' : `Add ${this.$tc('teams', 1)}`,
          formComponent: 'SettingsClinicalTeamForm',
          link: 'SettingsClinicalTeam',
          placeholder: this.$tc('teams', 2)
        })
      }
      if (this.userHasAccessToSettingsLocations()) {
        items.push({
          title: this.$tc('locations', 2),
          buttonText: this.$tc('locations', 1),
          prompt: this.$tc('locations', 1),
          modalTitle: this.settingEditingInForm ? `Edit ${this.settingEditingInForm.name}` : `Add ${this.$tc('locations', 1)}`,
          formComponent: 'SettingsLocationsForm',
          extraBottomPanelLeftComponent: 'SettingsLocationBottomPanelLeft',
          extraHeaderComponent: null,
          extraActionButtons: null,
          link: 'SettingsLocations',
          placeholder: this.$tc('locations', 2)
        })
      }

      return items
    }
  },
  beforeCreate () {
    document.body.className = 'body-settings-layout'
  },
  methods: {
    ...mapActions([
      'SET_SIDEBAR_ADD_STATUS'
    ]),
    ...mapMutations({
      toggleSettingsOpen: 'user/SETTINGS_OPEN',
      setSettingToEdit: EDITING_SETTING
    }),
    deleteItem () {
      this.$refs.formComponent.delete()
    },
    closeModal () {
      this.setSettingToEdit(null)
      this.showModal = false
    },
    triggerSave () {
      this.$refs.formComponent.save()
    },
    statusSelected (status) {
      this.$refs.formComponent.setStatus(status)
    },
    closeOverlay () {
      this.addingPatient = false
      componentRefreshSingleton.refreshComponents()
    },
    addPatient () {
      this.addingPatient = true
    },
    filtersChanged () {
      componentRefreshSingleton.refreshComponents()
    },
    updateClinicalUserSaveText (invite) {
      this.customSaveText = invite ? this.$t('saveAndInvite') : this.$t('saveAndClose')
    },
    openAddInOverlay () {
      this.$refs.view.openAdd()
    }
  }
}
</script>
