<template>
  <div>
    <button
      v-show="isUpdate"
      v-tooltip="tooltipData"
      type="button"
      class="btn btn-outline-secondary"
      :class="{disabled: deleteButtonDisabled}"
      @click="deleteItem"
    >
      {{ $t('deleteLocation') }}
    </button>
  </div>
</template>

<script>
import { Helpers } from '@/mixins/Helpers'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'SettingsLocationBottomPanelLeft',
  components: {},
  mixins: [Helpers],
  computed: {
    ...mapGetters({
      locations: 'getLocations',
      settingEditingInForm: 'getSettingEditingInForm'
    }),
    isUpdate () {
      return this.settingEditingInForm
    },
    deleteButtonDisabled () {
      return this.locations && this.locations.length <= 1
    },
    tooltipData () {
      return this.getTooltipData()
    }
  },
  methods: {
    ...mapMutations({}),
    deleteItem () {
      if (this.deleteButtonDisabled) {
        return false
      }

      this.$emit('deleteItem')
    },
    getTooltipData () {
      if (!this.deleteButtonDisabled) {
        return {}
      }

      return {
        content: this.$t('deleteLocationTooltip'),
        placement: 'top',
        classes: ['no-arrow'],
        delay: {
          show: 300,
          hide: 300
        }
      }
    }
  }
}
</script>
