
<template>
  <div class="roles-selector">
    <div class="header">
      <label>{{ $t('role_s') }} *</label>
      <i
        v-tooltip="tooltipData"
        class="fal fa-info-circle crud-info-icon"
        @click="tooltipData.show = !tooltipData.show"
        @mouseleave="tooltipData.show = false"
      />
    </div>
    <div class="roles-checkboxes">
      <div
        v-for="role in availableRoles"
        :key="role.id"
      >
        <div class="checkbox-container">
          <input
            :id="role.id"
            v-model="selectedRoles"
            type="checkbox"
            :name="role.id"
            :value="role.id"
            style="display:none"
            @input="emitChange"
          >
          <i
            :class="{'fal fa-check-square': selectedRoles.includes(role.id), 'fal fa-square': !selectedRoles.includes(role.id)}"
            @click="selectionChange(role.id)"
          />
          <label :for="role.id">{{ role.text }}</label>
        </div>
        <div v-if="showProviderId && role.id === clinicianRole.id">
          <div class="form-group provider-id">
            <label for="providerId">{{ $t('providerId') }}</label>
            <input
              id="providerId"
              v-model="modelProviderId"
              v-validate="`uniqueProviderId:${userId}`"
              type="text"
              data-vv-validate-on="blur"
              class="form-control"
              name="providerId"
              @blur="$emit('providerIdChange', modelProviderId)"
              @keyup="$emit('providerIdKeyUp')"
            >
            <span
              v-show="errors.has('providerId')"
              class="error"
            >{{ errors.first('providerId') }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Roles from '@/data/roles'
import { ROLE_CLINICIAN_OBJECT } from '@/store/modules/clinicalUsers/constants.js'

export default {
  name: 'RolesSelector',
  props: {
    /* eslint-disable */
    values: {
      type: Array
    },
    /* eslint-enable */
    providerId: {
      type: String,
      default: () => ''
    },
    userId: {
      type: String,
      default: () => ''
    }
  },
  data () {
    return {
      selectedRoles: [],
      modelProviderId: this.providerId,
      availableRoles: Roles,
      clinicianRole: ROLE_CLINICIAN_OBJECT,
      tooltipData: {
        content: `
        <strong>${this.$t('rolesinfo.staff.label')}</strong> ${this.$t('rolesinfo.staff.text')}<br><br>
        <strong>${this.$t('rolesinfo.clinicians.label')}</strong> ${this.$t('rolesinfo.clinicians.text')}<br><br>
        <strong>${this.$t('rolesinfo.supervisors.label')}</strong> ${this.$t('rolesinfo.supervisors.text')}<br><br>
        <strong>${this.$t('rolesinfo.admins.label')}</strong> ${this.$t('rolesinfo.admins.text')}
        `,
        classes: ['roles-tooltip'],
        trigger: 'manual',
        show: false
      }
    }
  },
  computed: {
    showProviderId () {
      return this.selectedRoles.includes(this.clinicianRole.id)
    }
  },
  created () {
    this.initValues()
  },
  methods: {
    selectionChange (value) {
      const index = this.selectedRoles.findIndex(v => v === value)
      if (index === -1) {
        this.selectedRoles.push(value)
      } else {
        this.selectedRoles.splice(index, 1)
      }
      this.emitChange()
    },
    emitChange () {
      this.$nextTick().then(() => {
        this.$emit('selectedRolesChanged', this.selectedRoles)
      })
    },
    initValues () {
      this.selectedRoles = this.values ? this.availableRoles.filter(r => this.values.includes(`${r.id}`)).map(r => r.id) : []
      if (this.values) {
        this.emitChange()
      }
    }
  }

}
</script>

<style lang="scss">
.roles-selector {
  .header {
    display: flex;
    align-items: center;
    justify-content: left;
    border-bottom: 2px solid #B3C1D1;
    margin-bottom: 15px;
    min-height: 21px;
    > span {
      margin-right: 10px;
      color: #0a3560;
      a {
        font-size: 12px;
        font-family: MuseoSans_300;
        font-weight: 300;
        color: #0a3560;
        text-transform: uppercase;
        &:hover {
          color: #2068ac;
          text-decoration: none;
        }
      }
    }
    i {
      margin-bottom: 0.5rem;
      margin-left: 10px;
    }
  }
  .checkbox-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-left: 15px;
    label {
      margin-bottom: 0;
      margin-left: 5px !important;
    }
  }
  .provider-id {
    padding: 0 5px 15px 5px !important;
    height: 101px;
    label {
      margin-left: 10px !important;
    }
    input {
      padding-left: 10px !important;
    }
    .error {
      white-space: nowrap;
      margin-left: 5px !important;
    }
  }
}
</style>
