<template>
  <span class="clinical-users-filters">
    <RoleFilter @filtersChanged="emitFiltersChanged" />
    <AccountStatusFilter
      :hide-no-contact="true"
      @filtersChanged="emitFiltersChanged"
    />
  </span>
</template>

<script>
import AccountStatusFilter from '@/components/admin/settings/extra/patientUsers/AccountStatusFilter'
import RoleFilter from '@/components/admin/settings/extra/clinicalUsers/RoleFilter'
export default {
  name: 'SettingsClinicalUsersFilters',
  components: {
    AccountStatusFilter,
    RoleFilter
  },
  methods: {
    emitFiltersChanged () {
      this.$emit('filtersChanged')
    }
  }
}
</script>

<style lang="scss">
.clinical-users-filters {
  display: flex;
  > div {
    margin-left: 25px;
  }
}
</style>
