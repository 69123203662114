<template>
  <div>
    <form
      class="settings-locations-form"
      novalidate
      @submit.prevent="save"
    >
      <div class="form-row">
        <div class="form-group col-sm-8">
          <label for="locationName">{{ $t('settingsLocationsForm.name') }} *</label>
          <input
            id="locationName"
            v-model="location.name"
            v-validate="'required'"
            type="email"
            class="form-control"
            name="locationName"
            :data-vv-as="$t('settingsLocationsForm.name')"
          >
          <span
            v-show="errors.has('locationName')"
            class="error"
          >{{ errors.first('locationName') }}</span>
        </div>
        <div
          v-if="isUpdate"
          class="form-group col-sm-4 update-data"
        >
          <div class="data">
            <div>{{ $t('locationId') }} <strong>{{ location.id }}</strong></div>
            <div>{{ $t('created') }} <span>{{ $toLocal(location.created_at, 'MMM DD, YYYY') }}</span></div>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col">
          <label for="locationAddress">{{ $t('settingsLocationsForm.addrress1') }}</label>
          <input
            id="locationAddress"
            v-model="location.address_1"
            type="text"
            class="form-control"
            name="locationAddress"
          >
        </div>
        <div class="form-group col">
          <label for="locationAddress2">{{ $t('settingsLocationsForm.addrress2') }}</label>
          <input
            id="locationAddress2"
            v-model="location.address_2"
            type="text"
            class="form-control"
            name="locationAddress2"
          >
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="locationCity">{{ $t('settingsLocationsForm.city') }}</label>
          <input
            id="locationCity"
            v-model="location.city"
            type="text"
            class="form-control"
            name="locationCity"
          >
        </div>
        <div class="form-group col-md-4">
          <label for="locationState">{{ $t('settingsLocationsForm.state') }}</label>
          <select
            id="locationState"
            v-model="location.state_id"
            name="locationState"
            class="form-control"
          >
            <option
              value=""
              selected
            />
            <option
              v-for="state in states"
              :key="state.id"
              :value="state.id"
            >
              {{ state.name }}
            </option>
          </select>
        </div>
        <div class="form-group col-md-2">
          <label for="locationZip">{{ $t('settingsLocationsForm.zip') }}</label>
          <input
            id="locationZip"
            v-model="location.zip"
            type="text"
            class="form-control"
            name="locationZip"
            maxlength="5"
          >
        </div>
      </div>
    </form>
    <ModalWrapper
      v-if="deleteMode"
      :hide-close-icon="true"
      :custom-class="`delete-location ${locationHasClinicians ? 'has-clinicians' : ''}`"
    />
  </div>
</template>

<script>
import ModalWrapper from '@/components/modal/modalWrapper'
import { ErrorsDictionary } from '@/mixins/ErrorsDictionary'
import { Helpers } from '@/mixins/Helpers'
import { DateTimeHelper } from '@/mixins/DateTimeHelper'
import { SAVE_LOCATION, GET_STATES, EDITING_SETTING, UPDATE_LOCATION, GET_HAS_USERS } from '@/store/modules/locations/constants'
import { MUTATION_SET_MODAL_OPENED, MUTATION_SET_MODAL_TEMPLATE } from '@/store/modules/applicationState/constants'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'SettingsLocationsForm',
  components: {
    ModalWrapper
  },
  mixins: [ErrorsDictionary, Helpers, DateTimeHelper],
  data () {
    return {
      location: {
        name: '',
        id: '',
        address_1: '',
        address_2: '',
        city: '',
        state_id: '',
        zip: ''
      },
      deleteMode: false,
      loaded: false
    }
  },
  computed: {
    ...mapGetters({
      states: 'getStates',
      locationHasClinicians: 'getLocationHasClinicians',
      settingEditingInForm: 'getSettingEditingInForm'
    }),
    isUpdate () {
      return this.settingEditingInForm
    },
    toastIndex () {
      return this.isUpdate ? 'fieldUpdated' : 'fieldAdded'
    },
    formAction () {
      return this.isUpdate ? UPDATE_LOCATION : SAVE_LOCATION
    },
    computedForm () {
      return Object.assign({}, this.location)
    }
  },
  watch: {
    computedForm: {
      handler (n, o) {
        if (this.loaded) {
          const isDirty = Object.keys(n).some(field => typeof n[field] === 'object' && n[field] !== null && o[field] !== null ? !this.$arraysEqual(n[field], o[field]) : n[field] !== o[field])
          this.$store.dispatch('SET_ACTIVE_FORM_DIRTY', isDirty)
        }
      },
      deep: true
    }
  },
  created () {
    if (!this.states.length) {
      this.$genericDispatch(GET_STATES)
    }
    if (this.settingEditingInForm) {
      this.location = { ...this.settingEditingInForm }
      this.$genericDispatch(GET_HAS_USERS, this.location.id).then(() => {
        this.loaded = true
      })
    } else {
      this.loaded = true
    }
  },
  destroyed () {
    this.setSettingToEdit(null)
    this.setHasClinicians(false)
    this.$store.dispatch('SET_ACTIVE_FORM_DIRTY', false)
  },
  methods: {
    ...mapMutations({
      setSettingToEdit: EDITING_SETTING,
      setDeleteModalOpen: MUTATION_SET_MODAL_OPENED,
      setHasClinicians: GET_HAS_USERS,
      setDeleteModalTemplate: MUTATION_SET_MODAL_TEMPLATE
    }),
    getFilteredData () {
      const data = {}
      for (const key in this.location) {
        if (this.location.hasOwnProperty(key)) { // eslint-disable-line no-prototype-builtins
          data[key] = this.location[key]
        }
      }
      return data
    },
    save () {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.$genericDispatch(this.formAction, this.getFilteredData()).then(() => {
            this.$toast.success({ message: this.$t(this.toastIndex, { name: this.location.name }) })
            this.closeForm()
          })
        }
      })
    },
    delete () {
      this.deleteMode = true
      this.setDeleteModalOpen(true)
      this.setDeleteModalTemplate('DeleteLocationModalContent')
    },
    closeForm () {
      this.setSettingToEdit(null)
      this.$emit('close')
    }
  }
}
</script>
