<template>
  <button
    v-if="canShowResetPasswordButton"
    type="button"
    class="btn btn-outline-secondary float-right"
    @click="resetPassword()"
  >
    {{ $t('resetPasswordButtonText') }}
  </button>
</template>

<script>
import { Helpers } from '@/mixins/Helpers'
import { mapGetters, mapMutations } from 'vuex'
import { STATUS_ACTIVE } from '@/data/userStatusValues'
import { DateTimeHelper } from '@/mixins/DateTimeHelper'

export default {
  name: 'SettingsClinicalUsersExtraActionButtons',
  mixins: [Helpers, DateTimeHelper],
  computed: {
    ...mapGetters({
      clinicalUserEditing: 'getSettingEditingInForm'
    }),
    canShowResetPasswordButton () {
      return this.clinicalUserEditing && this.clinicalUserEditing.id && this.clinicalUserCanResetPassword()
    }
  },
  methods: {
    ...mapMutations({}),
    userNeedSetup () {
      return this.clinicalUserEditing && this.clinicalUserEditing.need_setup
    },
    userInInvalidStatus () {
      return !(parseInt(this.clinicalUserEditing.status) === STATUS_ACTIVE)
    },
    clinicalUserCanResetPassword () {
      return !(this.userNeedSetup() || this.userInInvalidStatus())
    },
    resetPassword () {
      this.$genericDispatch('RESET_CLINICAL_USER_PASSWORD', this.clinicalUserEditing.id).then(() => {
        this.clinicalUserEditing.last_activity = { code: 5, datetime: this.getCurrentBrowserDate(null) }
        const name = `${this.clinicalUserEditing.first_name} ${this.clinicalUserEditing.last_name}`
        this.$toast.success({ message: this.$t('resetPasswordSucceedToast', { name: name }) })
        this.$emit('close')
      })
    }
  }
}
</script>
