<template>
  <div>
    <form
      class="settings-clinical-user-form"
      @submit.prevent="save"
    >
      <div
        class="form-row personal-information"
        :class="{ 'no-padding-bottom': errors.has('clinicalUserEmail') || errors.has('clinicalUserFirstName') || errors.has('clinicalUserLastName') }"
      >
        <div class="form-group col-md-3">
          <label for="clinicalUserFirstName">{{ $t('settingsClinicalUserForm.first_name') }} *</label>
          <input
            id="clinicalUserFirstName"
            v-model="clinicalUser.first_name"
            v-validate="'required'"
            type="text"
            class="form-control"
            name="clinicalUserFirstName"
            :data-vv-as="$t('settingsClinicalUserForm.first_name')"
          >
          <span
            v-show="errors.has('clinicalUserFirstName')"
            class="error"
          >{{ errors.first('clinicalUserFirstName') }}</span>
        </div>
        <div class="form-group col-md-3">
          <label for="clinicalUserLastName">{{ $t('settingsClinicalUserForm.last_name') }} *</label>
          <input
            id="clinicalUserLastName"
            v-model="clinicalUser.last_name"
            v-validate="'required'"
            type="text"
            class="form-control"
            name="clinicalUserLastName"
            :data-vv-as="$t('settingsClinicalUserForm.last_name')"
          >
          <span
            v-show="errors.has('clinicalUserLastName')"
            class="error"
          >{{ errors.first('clinicalUserLastName') }}</span>
        </div>
        <div class="form-group col-md-6">
          <label for="clinicalUserEmail">{{ emailAddressLabel }}</label>
          <input
            id="clinicalUserEmail"
            v-model="clinicalUser.email"
            v-validate="emailValidation"
            type="email"
            class="form-control"
            name="clinicalUserEmail"
            :data-vv-as="$t('settingsClinicalUserForm.email')"
          >
          <label
            v-if="showInviteNow"
            class="invite-label"
          >
            <input
              v-model="clinicalUser.invite"
              type="checkbox"
            >
            <span>{{ $t('inviteNow') }}</span>
          </label>
          <span
            v-show="errors.has('clinicalUserEmail')"
            class="error"
          >{{ errors.first('clinicalUserEmail') }}</span>
        </div>
      </div>
      <div class="form-row demographics">
        <div class="form-group col-md-4">
          <LocationSelector
            :key="clinicalUser.id"
            :values="clinicalUser.locations"
            @selectedLocationsChanged="selectedLocationsChanged"
          />
        </div>
        <div class="form-group col-md-3">
          <RolesSelector
            ref="roleSelector"
            :key="clinicalUser.id"
            :values="clinicalUser.roles"
            :provider-id="clinicalUser.provider_id"
            :user-id="clinicalUser.id ? `${clinicalUser.id}` : ''"
            @selectedRolesChanged="selectedRolesChanged"
            @providerIdChange="providerIdChange"
            @providerIdKeyUp="providerIdKeyUp"
          />
        </div>
        <div
          v-if="(showSupervisorSelector || showClinicianSelector) && relationshipLoaded"
          class="form-group col-md-5 relations"
        >
          <div class="label-container">
            <label>{{ relationSelectorLabelText }}</label>
            <i
              v-if="showSupervisorSelector"
              v-tooltip="tooltipData"
              class="fal fa-info-circle crud-info-icon"
              @click="tooltipData.show = !tooltipData.show"
              @mouseleave="tooltipData.show = false"
            />
          </div>
          <MultiSelectWrapper
            v-if="showSupervisorSelector && !supervisorsLoading"
            ref="relationshipSelector"
            v-model="supervisorSelectorModel"
            label="name"
            :sorting-mutation="multiSelectSortingMutation"
            :no-options="supervisorSelectorNoResults"
            :no-results="supervisorSelectorNoResults"
            :options="supervisorSelectorOptions"
            :vertical="true"
            :placeholder="relationSelectorPlaceholder"
          />
          <MultiSelectWrapper
            v-if="showClinicianSelector && !cliniciansLoading"
            ref="relationshipSelector"
            v-model="clinicianSelectorModel"
            label="name"
            :sorting-mutation="multiSelectSortingMutation"
            :no-options="supervisorSelectorNoResults"
            :no-results="supervisorSelectorNoResults"
            :options="clinicianSelectorOptions"
            :vertical="true"
            :placeholder="relationSelectorPlaceholder"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { ErrorsDictionary } from '@/mixins/ErrorsDictionary'
import { Helpers } from '@/mixins/Helpers'
import { DateTimeHelper } from '@/mixins/DateTimeHelper'
import { EDITING_SETTING } from '@/store/modules/locations/constants'
import {
  SAVE_CLINICAL_USER,
  UPDATE_CLINICAL_USER,
  GET_ACTIVE_SUPERVISORS,
  ROLE_SUPERVOSOR_OBJECT,
  ROLE_CLINICIAN_OBJECT,
  CHANGE_STATUS,
  SORT_SUPERVISORS_BY_RECENTLY_SELECTED
} from '@/store/modules/clinicalUsers/constants'
import { mapGetters, mapMutations } from 'vuex'
import LocationSelector from '@/components/admin/settings/common/LocationSelector'
import RolesSelector from '@/components/admin/settings/common/RolesSelector'
import MultiSelectWrapper from '@/components/admin/settings/common/MultiSelectWrapper'
import { USER_ROLE_CLINICIAN, USER_ROLE_SUPERVISOR } from '@/mixins/UserManagement/Roles'
import { GET_ACTIVE_CLINICIANS, SORT_CLINICIANS_BY_RECENTLY_SELECTED } from '@/store/modules/clinicians/constants.js'
import { STATUS_ICONS, STATUS_NOT_INVITED, STATUS_INVITATION_SENT, STATUS_INACTIVE } from '@/data/userStatusValues'

export default {
  name: 'SettingsClinicalUsersForm',
  components: { LocationSelector, RolesSelector, MultiSelectWrapper },
  mixins: [ErrorsDictionary, Helpers, DateTimeHelper],
  data: function () {
    return {
      emailValidation: '',
      supervisorSelectorModel: [],
      clinicianSelectorModel: [],
      providerIdInUse: false,
      supervisorsLoaded: false,
      cliniciansLoaded: false,
      locationsLengthError: true,
      relationshipLoaded: false,
      rolesLengthError: true,
      tooltipData: {
        content: this.$t('supervisorsTooltip'),
        classes: ['supervisor-tooltip'],
        trigger: 'manual',
        show: false
      },
      clinicalUser: {
        invite: true,
        locations: null,
        roles: null,
        superiors: [],
        provider_id: '',
        first_name: '',
        last_name: '',
        email: '',
        invited: '',
        user_name: '',
        subordinates: []
      }
    }
  },
  computed: {
    ...mapGetters({
      clinicalUserEditing: 'getSettingEditingInForm',
      cliniciansLoading: 'getCliniciansLoading',
      supervisorsLoading: 'getSupervisorsLoading',
      clinicians: 'getClinicians',
      supervisors: 'getSupervisors'
    }),
    emailAddressLabel () {
      const label = this.$t('settingsClinicalUserForm.email')
      const requiredString = this.emailValidation === '' ? '' : ' *'
      return label + requiredString
    },
    showInviteNow () {
      return !this.isUpdate || (this.isUpdate && this.userInNeedSetupStatus)
    },
    multiSelectSortingMutation () {
      return this.showClinicianSelector ? SORT_CLINICIANS_BY_RECENTLY_SELECTED : SORT_SUPERVISORS_BY_RECENTLY_SELECTED
    },
    isUpdate () {
      return this.clinicalUserEditing
    },
    toastIndex () {
      return this.isUpdate ? 'clinicalUserUpdated' : this.clinicalUser.invite ? 'clinicalUserInvited' : 'clinicalUserAdded'
    },
    formAction () {
      return this.isUpdate ? UPDATE_CLINICAL_USER : SAVE_CLINICAL_USER
    },
    computedForm () {
      return Object.assign({}, this.clinicalUser)
    },
    showSupervisorSelector () {
      return this.clinicalUser.roles && this.clinicalUser.roles.includes(USER_ROLE_CLINICIAN) && !this.clinicalUser.roles.includes(USER_ROLE_SUPERVISOR)
    },
    showClinicianSelector () {
      return this.clinicalUser.roles && this.clinicalUser.roles.includes(USER_ROLE_SUPERVISOR)
    },
    relationSelectorLabelText () {
      return this.showClinicianSelector ? this.$t('cliniciansSupervised') : `${this.$t('supervisors')}${this.$t('ifAny')}`
    },
    relationSelectorPlaceholder () {
      return this.showClinicianSelector ? this.$t('selectClinicians') : this.$t('selectSupervisors')
    },
    cliniciansNoSupervisors () {
      const clinicians = this.clinicians.filter(c => !c.roles.includes(ROLE_SUPERVOSOR_OBJECT.id))
      return this.getUsersFilteredBySelectedLocations(clinicians)
    },
    supervisorSelectorOptions () {
      return this.getUsersFilteredBySelectedLocations(this.supervisors)
    },
    clinicianSelectorOptions () {
      return this.getUsersFilteredBySelectedLocations(this.cliniciansNoSupervisors)
    },
    supervisorSelectorNoResults () {
      return this.showClinicianSelector ? this.$t('noCliniciansFound') : this.$t('noSupervisorsFound')
    },
    showUsername () {
      return this.isUpdate && this.canShowUsername
    },
    canShowUsername () {
      return !(this.userNeedSetup || this.userInNeedSetupStatus)
    },
    userNeedSetup () {
      return this.clinicalUser.need_setup
    },
    userInNeedSetupStatus () {
      return [STATUS_NOT_INVITED, STATUS_INVITATION_SENT].includes(Number(this.clinicalUser.status))
    }
  },
  watch: {
    'supervisorSelectorModel' () {
      this.populateSupervisorAndSubordinate()
    },
    'clinicianSelectorModel' () {
      this.populateSupervisorAndSubordinate()
    },
    'clinicalUser.roles' (newVal, oldVal) {
      this.rolesLengthError = !newVal || !newVal.length
      this.validateIfShouldShowSupervisorPrompt().then(() => {
        // empty  any relationship stored in the component
        this.updateSuperiorsAndSubordinatesList()
      })
    },
    'showSupervisorSelector' (newVal) {
      if (newVal && this.supervisorsLoaded) {
        this.initSupervisorSelectorModel('supervisors', this.clinicalUser.superiors)
      }
    },
    'showClinicianSelector' (newVal) {
      if (newVal && this.cliniciansLoaded) {
        this.initClinicianSelectorModel('clinicians', this.clinicalUser.subordinates)
      }
    },
    'clinicalUser.invite' (invite) {
      this.$emit('updateClinicalUserSaveText', invite)
    },
    'clinicalUser.locations' (newVal) {
      this.locationsLengthError = !newVal || !newVal.length
    },
    computedForm: {
      handler (n, o) {
        if (this.relationshipLoaded) {
          const isDirty = Object.keys(n).some(field => typeof n[field] === 'object' && n[field] !== null && o[field] !== null ? !this.$arraysEqual(n[field], o[field]) : n[field] !== o[field])
          this.$store.dispatch('SET_ACTIVE_FORM_DIRTY', isDirty)
        }
      },
      deep: true
    }
  },
  created () {
    this.$emit('footerError', null)
    this.clinicalUser.invite = !this.clinicalUserEditing
    this.$emit('updateClinicalUserSaveText', this.clinicalUser.invite)
    this.$validator.localize('en', this.errorsDictionary)
    if (this.clinicalUserEditing) {
      this.clinicalUser = { ...this.clinicalUserEditing }
    } else {
      this.relationshipLoaded = true
    }
    this.$genericDispatch(GET_ACTIVE_SUPERVISORS).then(() => {
      if (this.showSupervisorSelector) {
        this.supervisorsLoaded = true
        this.initSupervisorSelectorModel('supervisors', this.clinicalUser.superiors)
      } else {
        this.relationshipLoaded = true
      }
    })
    this.$genericDispatch(GET_ACTIVE_CLINICIANS).then(() => {
      if (this.showClinicianSelector) {
        this.cliniciansLoaded = true
        this.initClinicianSelectorModel('clinicians', this.clinicalUser.subordinates)
      } else {
        this.relationshipLoaded = true
      }
    })
    this.setEmailValidation()
  },
  destroyed () {
    this.setSettingToEdit(null)
    this.$emit('updateClinicalUserSaveText', false)
    this.$store.dispatch('SET_ACTIVE_FORM_DIRTY', false)
    this.$emit('footerError', null)
  },
  methods: {
    ...mapMutations({
      setSettingToEdit: EDITING_SETTING
    }),
    getUsersFilteredBySelectedLocations (users) {
      if (!this.clinicalUser.locations || !this.clinicalUser.locations.length) {
        return users
      }

      return users.filter(user => user.locations.some(
        l => this.clinicalUser.locations.includes(parseInt(l))
      ))
    },
    providerIdKeyUp () {
      if (this.providerIdInUse) {
        this.$refs.roleSelector.$validator.reset('provideId')
      }
    },
    providerIdChange (pId) {
      this.clinicalUser.provider_id = pId
      this.setProviderIdValidity()
    },
    setProviderIdValidity () {
      return new Promise((resolve) => {
        const interval = setInterval(() => {
          const roleSelector = this.$refs.roleSelector
          if (!roleSelector) {
            this.postProviderValidityActions(false, interval)
            return resolve(true)
          }

          const fieldIsPresent = roleSelector && roleSelector.$validator.flags && roleSelector.$validator.flags.providerId
          const fieldIsDirty = fieldIsPresent ? roleSelector.fields.providerId.dirty : false
          const haveToValidate = fieldIsPresent && fieldIsDirty
          if (!haveToValidate) {
            this.postProviderValidityActions(false, interval)
            return resolve(true)
          }

          if (roleSelector.$validator.flags.providerId.validated) {
            const result = roleSelector.$validator.flags.providerId.invalid
            this.postProviderValidityActions(result, interval)
            return resolve(true)
          }
        }, 500)
      })
    },
    postProviderValidityActions (validity, interval) {
      this.providerIdInUse = validity
      clearInterval(interval)
      return true
    },
    getFilteredData () {
      const data = {}
      for (const key in this.clinicalUser) {
        if (this.clinicalUser.hasOwnProperty(key)) { // eslint-disable-line no-prototype-builtins
          data[key] = this.clinicalUser[key]
        }
      }
      return data
    },
    save () {
      this.setProviderIdValidity().then(() => {
        this.$validator.validateAll().then((valid) => {
          this.clinicalUser.locations = this.clinicalUser.locations === null ? [] : this.clinicalUser.locations
          this.clinicalUser.roles = this.clinicalUser.roles === null ? [] : this.clinicalUser.roles
          const locationsOrRolesError = this.validateLocationsAndRoles()

          if (valid && !locationsOrRolesError && !this.providerIdInUse) {
            this.populateSupervisorAndSubordinate()
            if (this.clinicalUserEditing && this.clinicalUserEditing.status !== this.clinicalUser.status) {
              this.changeStatus().then(() => {
                this.dispatchSave()
              })
            } else {
              this.dispatchSave()
            }
          }
        })
      })
    },
    changeStatus () {
      return new Promise((resolve) => {
        const params = {
          id: this.clinicalUser.id,
          status: this.clinicalUser.status
        }
        this.$genericDispatch(CHANGE_STATUS, params).then(() => {
          this.$toast.success({ message: this.$t('userIsStatus', { status: STATUS_ICONS[params.status] }) })
        }).finally(() => {
          resolve()
        })
      })
    },
    dispatchSave () {
      this.$genericDispatch(this.formAction, this.getFilteredData()).then(() => {
        this.$toast.success({ message: this.$t(this.toastIndex, { name: `${this.clinicalUser.first_name} ${this.clinicalUser.last_name}` }) })
        this.closeForm()
      })
    },
    closeForm () {
      this.setSettingToEdit(null)
      this.$emit('close')
    },
    setStatus (status) {
      this.clinicalUser.status = status
      this.setEmailValidation()
    },
    setEmailValidation () {
      const inactive = this.clinicalUser && parseInt(this.clinicalUser.status) === STATUS_INACTIVE
      this.emailValidation = inactive ? '' : 'required|email'
    },
    selectedLocationsChanged (locations) {
      this.clinicalUser.locations = locations
      this.$nextTick().then(() => {
        this.validateLocationsAndRoles()
      })
    },
    updateSuperiorsAndSubordinatesList () {
      if (this.$refs.relationshipSelector) {
        this.$refs.relationshipSelector.resetSelected()
      }
    },
    selectedRolesChanged (roles) {
      this.clinicalUser.roles = roles
      this.$nextTick().then(() => {
        this.validateLocationsAndRoles()
      })
    },
    validateLocationsAndRoles () {
      const hasError = this.clinicalUser.locations !== null && this.clinicalUser.roles !== null && (this.locationsLengthError || this.rolesLengthError)
      let message = ''
      if (hasError) {
        const isPlural = this.locationsLengthError && this.rolesLengthError
        const item = isPlural ? this.$t('locationAndRole') : this.locationsLengthError ? this.$tc('locations', 1) : this.$tc('roles', 1)
        const verb = isPlural ? this.$t('are') : this.$t('is')
        message = this.$t('atLeast1LocationAndOrRole', { item: item.toLowerCase(), verb: verb })
      }
      this.$emit('footerError', message)
      return hasError
    },
    populateSupervisorAndSubordinate () {
      if (this.showClinicianSelector) {
        const ids = this.clinicianSelectorModel.map(i => i.id)
        this.clinicalUser.superiors = []
        this.clinicalUser.subordinates = ids
        return
      }
      if (this.showSupervisorSelector) {
        const ids = this.supervisorSelectorModel.map(i => i.id)
        this.clinicalUser.superiors = ids
        this.clinicalUser.subordinates = []
        return
      }
      this.clinicalUser.superiors = []
      this.clinicalUser.subordinates = []
    },
    initSupervisorSelectorModel (itemsKey, items) {
      this.supervisorSelectorModel = this[itemsKey].filter(e => items.includes(e.id))
      this.relationshipLoaded = true
    },
    initClinicianSelectorModel (itemsKey, items) {
      this.clinicianSelectorModel = this[itemsKey].filter(e => items.includes(e.id))
      this.relationshipLoaded = true
    },
    validateIfShouldShowSupervisorPrompt () {
      return new Promise((resolve) => {
        const hasClinicianAndSupervisor = this.clinicalUser.roles.includes(ROLE_SUPERVOSOR_OBJECT.id) && this.clinicalUser.roles.includes(ROLE_CLINICIAN_OBJECT.id)

        if (hasClinicianAndSupervisor && this.clinicalUser.superiors.length) {
          const promptOptions = {
            title: this.$t('areYouSure'),
            message: this.$t('superiorsWillBeRemoved'),
            cancelButton: this.$t('cancel'),
            okButton: this.$t('proceed')
          }
          this.$promptBeforeAction(promptOptions, resolve, this.removeSupervisorRole)
        } else {
          resolve()
        }
      })
    },
    removeSupervisorRole () {
      const index = this.clinicalUser.roles.findIndex(r => ROLE_SUPERVOSOR_OBJECT.id === r)
      if (index > -1) {
        this.clinicalUser.roles.splice(index, 1)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.relations{
  .label-container{
    min-height: 16px;
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    margin-top: 4px;
    label {
      margin-bottom: 0;
    }
    i {
      margin-left: 10px;
    }
  }
}

</style>
