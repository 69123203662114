<template>
  <div class="settings-clinical-team-form">
    <h1>SettingsClinicalTeamForm</h1>
  </div>
</template>

<script>

export default {
  name: 'SettingsClinicalTeamForm',
  methods: {
    save () {
      console.log('--------------saving SettingsClinicalTeamForm-') // eslint-disable-line no-console
    }
  }
}
</script>
