<template>
  <div
    v-if="item"
    class="settings-layout-top-bar"
  >
    <h3>{{ item.title }}</h3>
    <div class="rigt-items">
      <slot name="extraActions" />
      <search-input
        v-model="search"
        :placeholder="`Search ${item.placeholder}`"
        @input="onSearch(search)"
      />
      <button
        type="button"
        class="btn btn-secondary"
        @click="emitAction()"
      >
        Add {{ item.buttonText }}...
      </button>
    </div>
  </div>
</template>

<script>
import SearchInput from '@/components/common/SearchInput'
import { SET_SEARCHING } from '@/store/modules/locations/constants'
import { mapMutations } from 'vuex'

export default {
  name: 'SettingsLayoutTopBar',
  components: {
    SearchInput
  },
  props: ['item'],
  data () {
    return {
      search: ''
    }
  },
  computed: {
    action () {
      return this.item.addCustomAction ? this.item.addCustomAction : 'openCrudModal'
    }
  },
  watch: {
    $route () {
      this.search = ''
    }
  },
  destroyed () {
    this.onSearch('')
  },
  methods: {
    emitAction () {
      this.$emit(this.action)
    },
    ...mapMutations({
      onSearch: SET_SEARCHING
    })
  }
}
</script>
