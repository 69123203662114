<template>
  <div class="settings-programs-form">
    <h1>SettingsProgramsForm</h1>
  </div>
</template>

<script>

export default {
  name: 'SettingsProgramsForm',
  methods: {
    save () {
      console.log('--------------saving SettingsProgramsForm') // eslint-disable-line no-console
    }
  }
}
</script>
