<template>
  <FilterContent
    v-model="selected"
    :opened="opened"
    :selected-label="selectedLabel"
    :items="items"
    :selected="selected"
    @toggleOpened="opened = !opened"
    @close="close"
    @applyFilter="applyFilter"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import Roles from '@/data/roles'
import {
  USER_ROLE_CLINICIAN,
  USER_ROLE_SUPERVISOR,
  USER_ROLE_STAFF,
  USER_ROLE_ADMIN
} from '@/mixins/UserManagement/Roles'
import { Helpers } from '@/mixins/Helpers'
import FilterContent from '@/components/admin/settings/common/FilterContent'

export default {
  name: 'RoleFilter',
  components: { FilterContent },
  mixins: [Helpers],
  data () {
    return {
      opened: false,
      selected: this.initSelected(),
      selectedLabel: ''
    }
  },
  computed: {
    ...mapGetters({
      uiSettings: 'getUiSettings'
    }),
    items () {
      return Roles.map(r => ({ value: r.id, label: r.text }))
    },
    clinicianSelected () {
      return this.selected.includes(USER_ROLE_CLINICIAN)
    },
    supervisorSelected () {
      return this.selected.includes(USER_ROLE_SUPERVISOR)
    },
    staffSelected () {
      return this.selected.includes(USER_ROLE_STAFF)
    },
    adminSelected () {
      return this.selected.includes(USER_ROLE_ADMIN)
    }
  },
  created () {
    this.setSelectedLabel()
  },
  methods: {
    close () {
      this.opened = false
      this.selected = this.initSelected()
    },
    initSelected () {
      const clinicalUserRole = this.$store.getters.getUiSettings.clinicalUserRole
      const selected = clinicalUserRole ? [...clinicalUserRole.selected] : Roles.map(r => r.id)

      return selected
    },
    applyFilter () {
      this.opened = false
      const selected = [...this.selected]

      this.emitPatientSelectorChange([...new Set(selected)], 'clinicalUserRole')
      this.$store.dispatch('UPDATE_UI_SETTINGS', this.uiSettings).then(() => {
        this.$emit('filtersChanged')
        this.setSelectedLabel()
      })
    },
    emitPatientSelectorChange (val, settingKey) {
      // If selecting the same option prevent reload.
      const previousValue = this.uiSettings && this.uiSettings[settingKey] ? this.uiSettings[settingKey].selected : null
      if (previousValue && previousValue === val) {
        return false
      }
      // Save new user preferences
      this.$store.dispatch('UPDATE_UI_SETTING_VALUE', { settingKey: settingKey, settingValue: { selected: val } })
    },
    getSelectedLabel () {
      if (this.selected.length === 0) {
        return this.$t('select')
      }

      if (this.selected.length === 1) {
        return Roles.find(r => r.id === this.selected[0]).text
      }

      if (this.selected.length <= 3) {
        return this.$t('someRoles')
      }

      if (this.clinicianSelected && this.supervisorSelected && this.staffSelected && this.adminSelected) {
        return this.capitalizeString(this.$t('allRoles'))
      }
    },
    setSelectedLabel () {
      this.selectedLabel = this.getSelectedLabel()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
