<template>
  <div
    class="multi-select-wrapper"
    :class="{vertical: vertical}"
    :style="{'margin-bottom' : marginBottom}"
  >
    <multiselect
      ref="msw"
      v-model="model"
      :multiple="true"
      :placeholder="placeholder"
      :label="label"
      :track-by="label"
      :options="computedOptions"
      :max-height="265"
      :close-on-select="false"
      :clear-on-select="false"
      :preserve-search="true"
      :show-labels="false"
      :show-no-options="showNoOptions"
      @input="emitValue"
      @select="selectedOption"
      @remove="removedOption"
    >
      <div slot="afterList">
        <div class="button-container">
          <button
            type="button"
            class="btn btn-secondary btn-block btn-sm"
            @click="close"
          >
            {{ $t('done') }}
          </button>
        </div>
      </div>
      <div slot="noResult">
        <div class="no-results">
          {{ noResults }}
        </div>
      </div>
      <div slot="noOptions">
        <div class="no-options">
          {{ noOptions }}
        </div>
      </div>
    </multiselect>
  </div>
</template>

<script>

import Multiselect from 'vue-multiselect'
import { UPDATE_RECENTLY_SELECTED } from '@/store/modules/applicationState/constants'

export default {
  name: 'MultiSelectWrapper',
  components: { Multiselect },
  props: {
    label: {
      type: String,
      default: 'name'
    },
    vertical: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: 'Select...'
    },
    value: {
      type: Array,
      default: () => []
    },
    noResults: {
      type: String,
      default: 'No elements found. Consider changing the search query.'
    },
    noOptions: {
      type: String,
      default: null
    },
    sortingMutation: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      model: [...this.value]
    }
  },
  computed: {
    computedOptions () {
      return this.options
    },
    marginBottom () {
      return `${this.model.length * 40}px`
    },
    showNoOptions () {
      return this.noOptions && this.noOptions.length > 0
    }
  },
  methods: {
    emitValue () {
      this.$emit('input', this.model)
    },
    selectedOption (selectedOption) {
      this.updateRecentlySelected(selectedOption, true)
      this.reOrderPillElements()
    },
    removedOption (removedOption) {
      this.updateRecentlySelected(removedOption, false)
      this.$store.commit()
    },
    updateRecentlySelected (model, value) {
      this.$store.commit(UPDATE_RECENTLY_SELECTED, { model: model, value: value })
    },
    reOrderPillElements () {
      if (this.sortingMutation) {
        this.$store.commit(this.sortingMutation)
      }
    },
    close () {
      this.$refs.msw.deactivate()
    },
    reset () {
      this.model = []
    },
    resetSelected () {
      this.model = this.model.filter(
        selected => this.options.some(
          available => selected.id === available.id
        )
      )
    }
  }
}
</script>
